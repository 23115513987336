<template>
    <div 
        v-if="dashboardEdit"
        class="flex flex-col content-start h-full">
        
        <div class="flex-none bg-beige flex flex-col h-32">
            <div class="flex-grow flex px-4 pt-4">
                <div 
                    class="flex-none w-12 h-12 flex items-center justify-center"
                    @click="$emit('close')">
                    
                    <i class="fas fa-times text-black text-xl" />
                </div>
                <div class="flex-grow flex items-center justify-center">
                    <h1>{{dashboardEdit.name}}</h1>
                </div>
                <div class="flex-none w-12 h-12 flex items-center justify-center">
                    
                </div>
            </div>
            
            <Tabs 
                class="flex-none justify-center"
                :tabs="[
                    {
                        name: 'Detaljer',
                        alias: 'details',
                    },
                    /* {
                        name: 'Widgets',
                        alias: 'widgets',
                    }, */
                    {
                        name: 'Del',
                        alias: 'share',
                    },
                ]" 
                :active="activeSection" 
                @click="activeSection = $event.alias"
            />
        </div>
        
        <div class="flex-grow overflow-auto">
            <section 
                v-if="activeSection == 'details'"
                class="py-2 px-8">
                
                <form @submit.prevent="onUpdateDashboard()">
                    <InputSingleline 
                        v-model="dashboardEdit.name"
                        required="true"
                        label="Dashboard navn"
                        name="name"
                        showRequiredWarning="true"
                        ref="autofocus"
                    />
                    
                    <!-- <label>
                        <input 
                            type="checkbox" 
                            v-model="dashboardEdit.showTotal"
                        />
                        
                        Vis statistikk for total
                    </label>
                    
                    <label>
                        <input 
                            type="checkbox" 
                            v-model="dashboardEdit.showCampaigns"
                        />
                        
                        Vis statistikk for kampanjer
                    </label>
                    
                    
                    <label for="">Vis statistikk for avdelinger</label>
                    <div 
                        v-if="loading == 'fetching'"
                        class="h-full flex items-center justify-center">
                        
                        <div class="spinner"></div>
                    </div>
                    <div v-else-if="departments">
                        <div 
                            v-for="department in departments"
                            :key="'userEditDepartment_'+department.departmentId"
                            @click="onToggleDepartment(department.departmentId)"
                            class="cursor-default flex my-4 items-start">
                            
                            <input 
                                type="checkbox" 
                                :checked="Array.isArray(dashboardEdit.departments) ? dashboardEdit.departments.find( d => d == department.departmentId) : false"
                            />
                            
                            {{department.name}}
                        </div>
                    </div> -->
                    
                    <!-- <pre>{{dashboardEdit}}</pre> -->
                </form>
            </section>
            <!-- <section 
                v-else-if="activeSection == 'widgets'"
                class="py-2 px-8">
                
                Widgets
                <pre>{{dashboardEdit}}</pre>
            </section> -->
            <section 
                v-else-if="activeSection == 'share'"
                class="py-8 px-8 text-center">
                
                <router-link 
                    class="button submit"
                    :to="{ name: 'dashboard-show', params: {accessToken: dashboardEdit.accessToken} }"
                    target="_blank">
                    
                    Åpne fullskjerm
                </router-link>
                
                <!-- <iframe 
                    src="https://ecode.no" 
                    style="width: 1920px; height: 1080px; transform: scale(.18); transform-origin: 0 0;"
                /> -->
            </section>
        </div>
        
        <div 
            v-if="activeSection == 'details' || activeSection == 'widgets'"
            class="flex-none p-6 flex">
            
            <div class="flex-grow">
                <a 
                    class="button warning" 
                    @click.prevent="onDeleteDashboard()" >
                    
                    Slett
                </a>
            </div>
            <div class="flex-none space-x-4 text-right">
                <a 
                    class="button" 
                    @click="$emit('close')">
                    
                    Avbryt
                </a>
                
                <button 
                    type="submit"
                    @click.prevent="onUpdateDashboard()" 
                    :disabled="loading == 'updating'"
                    class="button submit"
                    :class="{'loading' : loading == 'updating'}">
                    
                    Lagre
                </button>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    props: {
        dashboard: {
            type: Object,
            required: true,
        }
    },
    
    computed:{
        departments(){
            return this.$store.getters.getDepartments;
        },
    },
    
    data() {
        return {
            loading: null,
            dashboardEdit: null,
            activeSection: 'details',
        }
    },
    
    methods: {
        async onDeleteDashboard(){
            try {
                if (! confirm('Er du sikker på at du vil slette dashboardet?')) return; 
                this.loading = 'deleting';
                const deleteDashboardResponse = await this.$store.dispatch('deleteDashboard', this.dashboardEdit);
                this.$notify({ type: 'success', text: 'Dashboard ble slettet' });
                this.$emit('close');
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    text: e.message || 'Kunne ikke slette dashboard'
                });
                
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        async onUpdateDashboard(){
            try {
                if ( !this.dashboardEdit.name ) {
                    throw Error('Et dashboard må ha et navn');
                }
                
                this.loading = 'updating';
                const dashboard = await this.$store.dispatch('updateDashboard', this.dashboardEdit);
                this.$notify({ type: 'success', text: 'Endringen ble lagret' });
                this.$emit('close');
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    text: e.message || 'Kunne ikke lagre endringen'
                });
                
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        onToggleDepartment( departmentId ){
            if ( this.dashboardEdit.departments.find( dD => dD == departmentId) ) {
                this.dashboardEdit.departments = this.dashboardEdit.departments.filter( dD => dD != departmentId );
            }
            else {
                this.dashboardEdit.departments.push( departmentId );
            }
        },
        
        createCopy(){
            this.dashboardEdit = JSON.parse(
                JSON.stringify({
                    // widgets: [],
                    departments: [],
                    showTotal: true,
                    showCampaigns: true,
                    ...this.dashboard,
                })
            );
        },
    },
    
    async mounted(){
        this.createCopy();
            
        this.loading = 'fetching';
        await this.$store.dispatch('listDepartments');
        this.loading = null;
    },
}
</script>