var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dashboardEdit)?_c('div',{staticClass:"flex flex-col content-start h-full"},[_c('div',{staticClass:"flex-none bg-beige flex flex-col h-32"},[_c('div',{staticClass:"flex-grow flex px-4 pt-4"},[_c('div',{staticClass:"flex-none w-12 h-12 flex items-center justify-center",on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"fas fa-times text-black text-xl"})]),_c('div',{staticClass:"flex-grow flex items-center justify-center"},[_c('h1',[_vm._v(_vm._s(_vm.dashboardEdit.name))])]),_c('div',{staticClass:"flex-none w-12 h-12 flex items-center justify-center"})]),_c('Tabs',{staticClass:"flex-none justify-center",attrs:{"tabs":[
                {
                    name: 'Detaljer',
                    alias: 'details',
                },
                /* {
                    name: 'Widgets',
                    alias: 'widgets',
                }, */
                {
                    name: 'Del',
                    alias: 'share',
                },
            ],"active":_vm.activeSection},on:{"click":function($event){_vm.activeSection = $event.alias}}})],1),_c('div',{staticClass:"flex-grow overflow-auto"},[(_vm.activeSection == 'details')?_c('section',{staticClass:"py-2 px-8"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onUpdateDashboard()}}},[_c('InputSingleline',{ref:"autofocus",attrs:{"required":"true","label":"Dashboard navn","name":"name","showRequiredWarning":"true"},model:{value:(_vm.dashboardEdit.name),callback:function ($$v) {_vm.$set(_vm.dashboardEdit, "name", $$v)},expression:"dashboardEdit.name"}})],1)]):(_vm.activeSection == 'share')?_c('section',{staticClass:"py-8 px-8 text-center"},[_c('router-link',{staticClass:"button submit",attrs:{"to":{ name: 'dashboard-show', params: {accessToken: _vm.dashboardEdit.accessToken} },"target":"_blank"}},[_vm._v(" Åpne fullskjerm ")])],1):_vm._e()]),(_vm.activeSection == 'details' || _vm.activeSection == 'widgets')?_c('div',{staticClass:"flex-none p-6 flex"},[_c('div',{staticClass:"flex-grow"},[_c('a',{staticClass:"button warning",on:{"click":function($event){$event.preventDefault();return _vm.onDeleteDashboard()}}},[_vm._v(" Slett ")])]),_c('div',{staticClass:"flex-none space-x-4 text-right"},[_c('a',{staticClass:"button",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Avbryt ")]),_c('button',{staticClass:"button submit",class:{'loading' : _vm.loading == 'updating'},attrs:{"type":"submit","disabled":_vm.loading == 'updating'},on:{"click":function($event){$event.preventDefault();return _vm.onUpdateDashboard()}}},[_vm._v(" Lagre ")])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }